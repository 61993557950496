import gql from "graphql-tag";
import moment from 'moment'
import 'moment-timezone';
import 'moment/locale/pt-br';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
    required, email,/*  confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length, */
} from '@validations'
import { getAuth } from "firebase/auth";
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BCardText,
    BInputGroupAppend,
    BFormGroup, BFormSelect,
    BDropdownItem,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BCardBody,
    BPagination,
} from "bootstrap-vue";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import Cleave from 'vue-cleave-component'
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import Portuguese from 'vee-validate/dist/locale/pt_BR'

import { Form } from 'vform';
import services from "@/services";

const UPDATE = gql`
mutation updateUser($uuid_user: uuid!, $user_rz : String, $user_first_name: String, $user_last_name: String, $user_cpf_cnpj: String, $user_email: String, $user_date_birth: date, $user_role: String, $status: String, $codcfo: String, $codcolcfo: String, $user_image: String, $user_fullname: String) {
  update_user(_set: {user_rz: $user_rz, user_first_name: $user_first_name, user_last_name: $user_last_name, user_cpf_cnpj: $user_cpf_cnpj, user_email: $user_email, user_date_birth: $user_date_birth, user_role: $user_role, status: $status, codcfo: $codcfo, codcolcfo: $codcolcfo,  user_image: $user_image, user_fullname: $user_fullname}, where: {uuid_user: {_eq: $uuid_user}}) {
    affected_rows
  }
}
`;
const GET_USERS = gql`
query getUsers {
    user {
      uuid_user
      user_rz
      user_email
      user_first_name
      user_last_name
      user_fullname
      user_role
      user_cpf_cnpj
      user_date_birth
      user_image
      user_firebase_uid
      codcolcfo
      codcfo
      created_at
      updated_at
      status
    }
  }
  `;

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        ToastificationContent,
        BCardText,
        BCard,
        BRow,
        BCol,
        Cleave,
        BFormInput,
        BCardBody,
        BCardCode,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup, BFormSelect,
        BLink,
        BInputGroupAppend,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        BInputGroupPrepend,
        vSelect,
    },
    data() {
        return {
            users: [],
            email,
            required,
            titleForm: 'Adicionar usuário',
            editMode: false,
            formUser: new Form({
                uuid_user: null,
                user_fullname: null,
                user_rz: null,
                user_first_name: null,
                user_firebase_uid: null,
                user_last_name: null,
                user_date_birth: null,
                user_image: null,
                user_cpf_cnpj: null,
                user_email: null,
                user_password: null,
                codcolcfo: null,
                codcfo: null,
                user_role: null,
                status: null,
            }),
            roles: [{ id: 1, title: 'Corretor (a)' }, { id: 2, title: 'Imobiliária' }, { id: 3, title: 'Manager' }, { id: 4, title: 'Administrador' }],
            status_options: [{ id: 1, title: 'Ativo' }, { id: 2, title: 'Inativo' }],
            options: {
                date: {
                    date: true,
                    delimiter: '/',
                    datePattern: ['d', 'm', 'Y'],
                },
            },
            perPage: 5,
            pageOptions: [5, 10, 15],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [
                { key: 'user', label: 'Usuário', sortable: true },
                { key: 'email', label: 'E-mail', sortable: false },
                { key: 'cpf-cnpj', label: 'CPF / CNPJ', sortable: false },
                { key: 'status', label: 'Status', sortable: true },
                { key: 'actions', label: 'Ações', sortable: false },
            ],
        }
    },
    created() {
        this.getRecords();
        localize('pt_BR', Portuguese);
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({ text: f.label, value: f.key }))
        },
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (this.editMode == true) {
                        this.updateUser();
                    } else {
                        this.newUser()
                    }
                }
            })
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        nUser() {
            this.titleForm = 'Adicionar Usuário',
                this.editMode = false;
            this.formUser.reset();
            this.$refs['userModal'].show()
        },
        updateUser() {
            let that = this
            if (that.formUser.user_role.id == 1) {
                that.formUser.user_role = "broker"
            }
            if (that.formUser.user_role.id == 2) {
                that.formUser.user_role = "real_estate"
            }
            if (that.formUser.user_role.id == 3) {
                that.formUser.user_role = "manager"
            }
            if (that.formUser.user_role.id == 4) {
                that.formUser.user_role = "administrator"
            }
            if (that.formUser.status.id == 1) {
                that.formUser.status = "active"
            }
            if (that.formUser.status.id == 2) {
                that.formUser.status = "inative"
            }
            let {
                user_email,
                uuid_user,
                user_first_name,
                user_last_name,
                user_role,
                user_cpf_cnpj,
                user_date_birth,
                user_image,
                user_rz,
                codcolcfo,
                codcfo,
                status,
            } = this.$data.formUser;
            let user_fullname = this.formUser.user_first_name + ' ' + this.formUser.user_last_name

            this.$apollo.mutate({
                mutation: UPDATE,
                variables: {
                    uuid_user,
                    user_rz,
                    user_email,
                    user_first_name,
                    user_last_name,
                    user_fullname,
                    user_role,
                    user_cpf_cnpj,
                    user_date_birth,
                    user_image,
                    codcolcfo,
                    codcfo,
                    status,
                }
            }).then(() => {
                this.getRecords();
                this.$refs['userModal'].hide()

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sucesso',
                        icon: 'CheckIcon',
                        text: 'Usuário atualizado!',
                        variant: 'success',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
                var http = new XMLHttpRequest();
                var url = 'https://us-central1-portais-saobento.cloudfunctions.net/permission/change_permission';
                const auth = getAuth();
                auth.currentUser.getIdToken().then((token) => {

                    http.open('POST', url, true);
                    http.setRequestHeader('Content-type', 'application/json');
                    http.onreadystatechange = function () {
                        if (http.readyState == 4 && http.status == 200) {
                            // http.responseText
                        }
                    }
                    http.setRequestHeader("Authorization", 'Bearer ' + token);
                    http.send(JSON.stringify(
                        {
                            "email": user_email,
                            "allowed_roles": [user_role]
                        }
                    ));
                });
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Erro',
                        icon: 'XIcon',
                        text: 'Erro ao atualizar usuário! ' + error,
                        variant: 'danger',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            });
        },
        formatDatetoUpdate(data) {
            return moment(data).format("MM-DD-YYYY")
        },

        async newUser() {

            const { errors } = await services.auth.createUser(
                this.formUser.user_email,
                this.formUser.user_password,
                this.$data.formUser
            )

            if (errors) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Erro',
                        icon: 'XIcon',
                        text: 'Erro ao criar usuário!',
                        variant: 'danger',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            }
            else {
                this.getRecords();
                this.$refs['userModal'].hide()
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sucesso',
                        icon: 'CheckIcon',
                        text: 'Usuário cadastrado!',
                        variant: 'success',
                    },
                },
                    {
                        position: 'bottom-right',
                    })
            }
        },

        editUser(user) {
            let that = this
            this.titleForm = 'Editando Usuário';
            this.editMode = true;
            this.$data.formUser.fill(user);
            if (this.formUser.user_date_birth == null) {
                // sem data
            } else {
                this.formUser.user_date_birth = this.formatDatetoUpdate(this.formUser.user_date_birth);
            }
            if (that.formUser.user_role == 'broker') {
                that.formUser.user_role = { id: 1, title: 'Corretor (a)' }
            }
            if (that.formUser.user_role == 'real_estate') {
                that.formUser.user_role = { id: 2, title: 'Imobiliária' }
            }
            if (that.formUser.user_role == 'manager') {
                that.formUser.user_role = { id: 3, title: 'Manager' }
            }
            if (that.formUser.user_role == 'administrator') {
                that.formUser.user_role = { id: 4, title: 'Administrador' }
            }
            if (that.formUser.status == 'active') {
                that.formUser.status = { id: 1, title: 'Ativo' }
            }
            if (that.formUser.status.id == 'inative') {
                that.formUser.status = { id: 2, title: 'Inativo' }
            }

            this.$refs['userModal'].show()
        },
        userFullName() {
            return this.user_fullname = this.user_first_name + ' ' + this.user_last_name;
        },
        formatDate(valueDate, destination) {
            if (destination == "save") {
                if (valueDate == "" || valueDate == null) {
                    valueDate = null;
                } else {
                    valueDate = valueDate.split("/");
                    valueDate = valueDate[2] + "-" + valueDate[1] + "-" + valueDate[0];
                }
            } else {
                valueDate = valueDate.split("-");
                valueDate = valueDate[2] + "/" + valueDate[1] + "/" + valueDate[0];
            }
            return valueDate;
        },
        getRecords() {
            this.$apollo.query({
                query: GET_USERS,
                fetchPolicy: 'no-cache',
            }).then((data) => {
                this.users = data['data']['user'];
                this.totalRows = this.users.length
            });
        }
    },
    setup() {
        return {
            avatarText,
        };
    },
};
